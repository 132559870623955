/* AboutMe.css */

.about-me-content {
    background-color: transparent; /* Background color is managed by the overlay */
    padding: 4vw;
    border-radius: 10px;
    max-width: 60%;
    padding-top: 0;
    color: #fae9de;
}

.about-me-title {
    font-size: 4vw; /* Responsive font size */
    margin-bottom: 2vh;
    font-weight: bold;
}

.about-me-text {
    font-size: 1.5vw; /* Responsive font size */
    line-height: 1.6;
    margin-bottom: 7vh;
}

.cv-button {
    display: inline-block;
    background-color: #fae9de;
    color:black;
    padding: 1.5vw 3vw;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 1.5vw; /* Responsive font size */
    opacity: 0.7;
}

.cv-button:hover {
    background-color: #000000;
    color: #ffffff;
}

@media (max-width: 768px) {
    .about-me-content {
        max-width: 90%; /* Increase width for smaller screens */
        padding: 2vh; /* Reduce padding */
        padding-top: 6vh;
    }

    .about-me-title {
        font-size: 6vw; /* Larger font size on smaller screens */
        margin-bottom: 3vh; /* Add more space below the title */
    }

    .about-me-text {
        font-size: 3.5vw; /* Increase font size for readability */
        margin-bottom: 3vh; /* Adjust margin */
        text-align: left; /* Text alignment left for readability */
    }

    .cv-button {
        padding: 3vw 5vw; /* Increase padding for buttons */
        font-size: 3.5vw; /* Adjust font size for smaller screens */
    }
}
