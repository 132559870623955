/* Contact Me Section */
.contact-me-content {
    text-align: center;
    color: #fae9de;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15vh; /* Ensure some space at the top */
}

.contact-me-title {
    font-size: 4vw;
    margin-bottom: 2vh;
    font-weight: bold;
    color: #fae9de;
}

.contact-me-description {
    font-size: 1.5vw;
    margin-bottom: 4vh;
    line-height: 1.6;
    color: #fae9de;
}

/* Social Links container */
.social-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center items horizontally */
    gap: 40px; /* Adjust gap between items */
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}

.social-link {
    display: block;
    width: 7vw; /* Set width for social icons */
    height: 7vw; /* Set height for social icons */
    text-decoration: none;
    text-align: center;
    opacity: 0.7;
    transition: transform 0.3s ease, opacity 0.3s ease;
    padding-bottom: 3vh;
}

.social-link img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: transparent;
    border-radius: 10px;
}

.social-link:hover {
    transform: scale(1.1);
    opacity: 1;
}

.social-link span {
    display: block;
    margin-top: 5px;
    font-size: 1.5vw;
    color: #fae9de;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
}

/* Mobile Adjustments */
@media (max-width: 767px) {
    .contact-me-content {
        padding-top: 5vh;
    }
    
    .contact-me-title {
        font-size: 8vw;
        margin-bottom: 2vh;
    }

    .contact-me-description {
        font-size: 4vw;
        margin-bottom: 2vh;
    }

    .social-links {
        gap: 10vw; /* Adjust gap for mobile */
        justify-content: center; /* Ensure icons are centered */
    }

    .social-link {
        width: 15vw; /* Increase size of icons on mobile */
        height: 20vw;
    }

    .social-link span {
        font-size: 4vw; /* Adjust font size for mobile */
    }

    .social-link:hover {
        transform: scale(1);
        opacity: 0.7;
    }

}








