.footer {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)); /* Gradient fade-out effect */
  color: #fae9de;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 1vw;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5vh; /* Slightly taller to emphasize the fade-out */
  z-index: 10;
}

.footer-left {
  font-size: 1rem;
}

.footer-right {
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: flex-end;
}

.footer-signature {
  height: 3vh; /* Adjust the size of the signature image */
  margin-left: 0.5rem; /* Add some space between the image and text */
}

@media (max-width: 768px) {
  .footer {
    display: flex;
    justify-content: center; /* Center the signature horizontally */
    align-items: center; 
  }

  .footer-left {
    display: none;
  }

  .footer-right {
    display: flex;
    justify-content: center; /* Center-align for mobile */
    align-items: center; 
    font-size: 0.7rem; /* Adjust font size for smaller devices */

  }

  .footer-signature {
    height: 2vh; /* Further reduce image size */
  }
}
