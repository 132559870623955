/* Base Styles (apply to all screens unless overridden by media queries) */
.thoughts-content {
    text-align: center;
    color: #fae9de;
    padding: 0 20px;
    padding-bottom: 10vh;
}

.thought-content p {
    font-size: 1.2vw; /* Responsive font size */
    color: #fae9de;
    line-height: 1.8; /* Maintain readability */
    text-align: justify; /* Align paragraphs for better appearance */
    margin: 0 0 1.5vh 0; /* Add spacing between paragraphs */
    hyphens: auto; /* Enable word breaking for long words */
    word-wrap: break-word; /* Prevent text from overflowing */
}

.thoughts-title {
    font-size: 4vw; /* Proportional size for readability */
    margin-bottom: 2vh;
    font-weight: bold;
    color: #fae9de;
}

.thoughts-description {
    font-size: 1.5vw; /* Clear description */
    margin-bottom: 4vh;
    line-height: 1.6;
    color: #fae9de;
}

.thoughts-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Maintain consistent spacing */
    position: relative;
    padding-top: 20px;
    overflow-y: visible; /* Allow full-page scrolling */
    max-height: none; /* Let list expand naturally */
}

.thought-item {
    position: relative;
    width: 90%;
    background-color: rgba(250, 233, 222, 0.05); /* Default background color */
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    transition: all 0.5s ease; /* Smooth transitions for height and background */
}

.thought-item.expanded {
    height: auto; /* Dynamically adjust height based on content */
    background-color: rgba(250, 233, 222, 0.05); /* Retain default background when expanded */
}

.thought-item:hover {
    background-color: rgba(250, 233, 222, 0.10); /* Highlight hovered item */
}

.thought-item.expanded:hover {
    background-color: rgba(250, 233, 222, 0.10); /* Lighter background only on hover, even when expanded */
}

.thought-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vh 2vw; /* Reduce padding for compact design */
    margin: 0; /* Remove unnecessary margins */
}

.thought-title {
    font-size: 2vw;
    text-align: center;
    padding: 0;
    padding-top: 2vh;
    font-weight: bold;
    line-height: 1.3;
    opacity: 1;
    z-index: 2;
    transition: opacity 0.3s ease; /* Smooth fade-out */
    margin: 0;
}

.toggle-button {
    font-size: 1.5vw; /* Adjust size as needed */
    background: none;
    border: none;
    color: #fae9de;
    cursor: pointer;
    transform: rotate(0deg) scale(1, 0.6); /* Default state, flat triangle */
    transform-origin: center center; /* Rotate around the center of the button */
    transition: transform 0.3s ease; /* Smooth transition */
    outline: none; /* Remove the focus outline */
}

.thought-item.expanded .toggle-button {
    transform: rotate(360deg) scale(1, 0.6); /* Rotate 180 degrees for expanded state */
    transform-origin: center center; /* Keep the same rotation origin */
}

.thought-content {
    opacity: 0; /* Hidden initially */
    height: 0; /* No height initially */
    padding: 0 2vw; /* Padding starts at 0 */
    overflow: hidden; /* Prevent overflow during transition */
    transition: height 0.6s ease, padding 0.5s ease, opacity 0.3s ease 0.3s; /* Delay opacity */
}

.thought-item.expanded .thought-content {
    opacity: 1; /* Gradually fade in text */
    height: auto; /* Expand height naturally */
    padding: 2vw; /* Restore padding */
}

.thought-content-text {
    font-size: 1.2vw; /* Readable text size */
    color: #fae9de;
    line-height: 1.8;
    text-align: justify;
    hyphens: auto;
}

/* Remove scrollbars for all browsers */
.thoughts-list {
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
}

.thoughts-list::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, Opera */
}


/* Mobile Version (for screens with max-width: 767px) */
@media (max-width: 600px) {
    .thoughts-content {
        text-align: center;
        color: #fae9de;
        padding: 0;
    }

    .thought-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        overflow: hidden;
        height: auto;
    }

    .thoughts-title {
        font-size: 7vw; /* Adjusted for mobile readability */
    }

    .thought-title {
        font-size: 4vw; /* Adjusted for mobile readability */
        text-align: center;
        margin: 0;
        font-weight: bold;
        line-height: 1.3;
        opacity: 1;
        z-index: 2;
        transition: opacity 0.3s ease; /* Smooth fade-out */
    }

    .thoughts-description {
        font-size: 4vw;
        margin-bottom: 6vh;
    }

    .thoughts-list {
        width: 100%;
        padding-top: 10px;
    }

    .thought-item {
        position: relative;
        width: 100%;
        background-color: rgba(250, 233, 222, 0.05);
        border-radius: 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        height: 20vh; /* Fixed height for mobile to prevent size changes */
        transition: background-color 0.3s ease; /* Smooth transitions */
    }
    
    .thought-item.expanded {
        height: 20vh; /* Retain the same height when expanded */
        background-color: rgba(250, 233, 222, 0.10); /* Optional: lighter background */
    }

    /* Title behavior */
    .thought-title {
        font-size: 4vw; /* Consistent size */
        text-align: center;
        margin: 0;
        font-weight: bold;
        line-height: 1.3;
        opacity: 1;
        z-index: 2;
        transition: opacity 0.8s ease-in-out;  /* Smooth fade-out */
        position: absolute; /* Prevent it from affecting layout */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        transform: translate(-50%, -50%); /* Adjust for perfect centering */
    }
    
    .thought-item.expanded .thought-title {
        opacity: 0; /* Hide the title when expanded */
    }
    
    

    /* Toggle button covering the entire item */
    .toggle-button {
        position: absolute; /* Position to cover the thought-item */
        top: 0;
        left: 0;
        width: 100%; /* Cover entire thought-item width */
        height: 100%; /* Cover entire thought-item height */
        background: none; /* Transparent background */
        border: none; /* Remove borders */
        cursor: pointer;
        z-index: 3; /* Keep it above other elements */
        opacity: 0; /* Invisible but clickable */
        pointer-events: auto;
        transition: transform 0.3s ease; /* Smooth rotation animation */
        outline: none; /* Remove focus outline */
        transform: none;
    }

    .toggle-button:focus,
.toggle-button:active {
    outline: none; /* Remove the blue outline for focus */
    box-shadow: none; /* Remove any browser-specific highlight effects */
    transform: none;
}

    .thought-item.expanded .toggle-button {
        height: 100%; /* Ensure it spans the full expanded height */
        transform: none;
    }

    /* Content behavior */
    
    .thought-content {
        opacity: 0; /* Fully hidden */
        visibility: hidden; /* Hidden but still occupies space */
        padding: 2vw; /* Consistent padding for the container */
        text-align: justify;
        width: 100%;
        height: 100%; /* Fixed height to prevent resizing */
        z-index: 1;
        box-sizing: border-box; /* Include padding in calculations */
        overflow: hidden; /* Prevent scrolling during transitions */
        transition: opacity 0.8s ease-in-out, visibility 0s linear 0.8s; /* Smooth fade-in and delayed visibility */
    }
    
    .thought-item.expanded .thought-content {
        opacity: 1; /* Fully visible */
        visibility: visible; /* Ensure visibility matches opacity */
        overflow-y: auto; /* Enable scrolling when expanded */
        transition: opacity 0.8s ease-in-out, visibility 0s linear 0s; /* Visibility appears instantly when expanded */
    }
    
    .thought-content p {
        font-size: 1.9vw; /* Larger font size for readability */
        line-height: 1.8; /* Consistent line spacing */
        margin: 0; /* Prevent layout shifts */
        padding: 2.5vw; /* No padding to interfere with container */
        opacity: 1; /* Fully visible by default */
        text-align: justify;
        width: 100%; /* Ensure paragraph takes full width */
        box-sizing: border-box; /* Match container's box model */
        transition: opacity 0.8s ease-in-out; /* Smooth fade-in and fade-out */
    }
    
    .thought-item.expanded .thought-content p {
        opacity: 1; /* Paragraph fully visible */
        transition: opacity 0.8s ease-in-out; /* Smooth fade-in */
    }
    
    .thought-item:not(.expanded) .thought-content p {
        opacity: 0; /* Paragraph fades out smoothly */
    }    
    

    .thought-item:focus, 
.thought-item:active, 
.toggle-button:focus, 
.toggle-button:active {
    outline: none; /* Remove the default focus outline */
    box-shadow: none; /* Remove any additional highlight effects */
}
}









