/* Base Styles (apply to all screens unless overridden by media queries) */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #000; /* Background color for the entire site */
  color: white; /* Universal text color */
  overflow-y: scroll; /* Enables vertical scrolling */
  scrollbar-width: none; /* For Firefox */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

html {
  overflow-y: scroll; /* Allows vertical scrolling */
  scrollbar-width: none; /* For Firefox */
  scroll-behavior: smooth;
}

p, a {
  font-family: 'Montserrat', sans-serif;
}

html::-webkit-scrollbar {
  display: none; /* Hides the scrollbar in Chrome, Safari, Edge */
}

/* Universal class to reset margins and padding for sections */
.section {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Custom Layout for Academic Section */
.custom-academic-layout {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

.custom-writing-layout {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

/* Media Query for Phone (Mobile) Screens */
@media (max-width: 767px) {
  .background-content.custom-academic-layout, .background-content.custom-writing-layout  {
    width: 100% !important;  /* Full width */
    margin: 0 auto !important; /* Remove unwanted margin */
    padding-left: 0 !important; /* Adjust padding */
    padding-right: 0 !important;
    text-align: left;  /* Text alignment for readability */
  }

  body {
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  /* Adjust font size and spacing for mobile */
  h1 {
    font-size: 6vw;
  }

  p, a {
    font-size: 4vw;
  }
}



