/* Desktop Version (Original) */
.academic-title {
    font-size: 4vw;
    margin-bottom: 2vh;
    font-weight: bold;
    color: #fae9de;
}

.academic-description {
    font-size: 1.5vw;
    margin-bottom: 4vh;
    line-height: 1.6;
    color: #fae9de;
}

.papers-list {
    border-top: 1px solid #fae9de;
    padding-top: 2vh;
    padding-bottom: 2vh; /* Add some padding to the bottom of the papers list */
    margin: 0 auto; /* Center the list horizontally */
}

.paper-item {
    margin-bottom: 2vh;
    background-color: rgba(0, 0, 0, 0.5); /* Set background to black with 0.6 opacity */
    padding: 2vw;
    border-radius: 5px;
    transition: background-color 0.5s ease;
    display: flex;
    flex-direction: column; /* Stack title, description, and buttons vertically */
    justify-content: flex-start;
}

.paper-item:hover {
    background-color: rgba(250, 233, 222, 0.05);
}

.paper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paper-title {
    font-size: 1.8vw;
    margin: 0;
    text-align: left;
    flex: 1; /* Ensure the title takes up available space */
    padding-right: 5vw;
    color: #fae9deeb;
    padding-top: 2vh;
}

.paper-buttons {
    display: flex;
    gap: 1vw;
}

.paper-button {
    background-color: #fae9de;
    color: #000000;
    padding: 1vw 2vw;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 1.2vw;
}

.paper-button:hover {
    background-color: #000000;
    color: #fae9de;
}

/* Style for Read Translation button */
.paper-button-translation {
    background-color: rgba(255, 255, 255, 0.7); /* Slightly transparent */
    color: #000000;
    padding: 1vw 2vw;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease, color 
}

.paper-description {
    max-height: none; /* Make sure description is fully visible */
    opacity: 1; /* Ensure description is visible */
    padding-top: 1vh;
    padding-bottom: 1vh;
    text-align: left;
    color: #fae9de;
    line-height: 1.6;
}

/* Incorporating the Date of Each Post */
.paper-date {
    position: absolute;
    top: 1vh;
    right: 2vw;
    font-size: 1.2vw; /* Default font size */
    font-style: italic; /* Default italics */
    color: #fae9de; /* Light color to contrast */
    z-index: 1; /* Ensure the date appears above other content */
    margin: 0;
    padding: 0;
    padding-top: 1vh;
}

/* Allow for easy customization in desktop and mobile */
.paper-item {
    position: relative; /* Ensure the date is placed relative to the paper item */
}

@media (max-width: 767px) {
    .paper-date {
        position: absolute;
        top: 1vh;
        right: 2vw;
        font-size: 3vw; /* Default font size */
        font-style: italic; /* Default italics */
        color: #fae9de; /* Light color to contrast */
        z-index: 1; /* Ensure the date appears above other content */
        margin: 0;
        padding: 0;
        padding-right: 2vw;
    }

    .academic-title {
        font-size: 8vw; /* Make the title much larger on mobile */
        text-align: center; /* Center the title horizontally */
        margin-bottom: 2vh; /* Space between title and description */
    }

    .academic-description {
        font-size: 4vw; /* Adjust description size */
        color: #fae9de; /* Make sure the text is light */
        text-align: center; /* Center the description */
        margin-bottom: 4vh;
        padding: 0 5vw;
    }

    .academic-title::after {
        content: none !important; /* Remove any pseudo-element line */
    }

    .papers-list {
        padding: 0;
    }

    .paper-item {
        width: 100%;
        background-color: #2a2a2a; /* Default background for card */
        color: white; /* Default font color */
        padding: 5vw; /* Padding for content */
        margin-bottom: 0; /* Space between paper items */
        border-radius: 0; /* Rounded corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        display: flex;
        flex-direction: column; /* Stack title, description, and buttons vertically */
        justify-content: flex-start;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .paper-title {
        font-size: 5vw; /* Larger font size for mobile */
        color: white; /* Title contrasts with background */
        margin-bottom: 1vh; /* Space between title and description */
        text-align: center; /* Center title */
        width: 100%; /* Full width for title */
    }

    .paper-description {
        font-size: 3vw; /* Smaller description font */
        padding-top: 0;
        padding-bottom: 0;
        color: #fae9de; /* Light text for description */
        line-height: 1.6;
        text-align: center; /* Center description */
        margin-bottom: 2vh; /* Space between description and buttons */
    }

    .paper-buttons {
        display: flex;
        justify-content: center; /* Center buttons horizontally */
        gap: 2vw;
        width: 100%; /* Ensure buttons take full width */
    }

    .paper-button {
        font-size: 3.5vw;
        padding: 2vw 4vw;
        background-color: #fae9de;
        color: #000000;
        border-radius: 5px;
        text-decoration: none;
        font-weight: bold;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .paper-button:hover {
        background-color: #000000;
        color: #fae9de;
    }

    /* Use alternating background colors for mobile */
    .paper-item:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.2);
        color: #ffffff;
    }

    .paper-item:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.2);
        color: #ffffff;
    }
}