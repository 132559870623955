/* BackgroundSection.css */

.background-section {
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  transition: opacity 1.5s ease-in-out;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.background-content {
  position: relative;
  z-index: 2;
  height: 100%;
  box-sizing: border-box;
  width: 70%; /* Default width, override with props */
  margin: 0 auto;
  padding-left: 5vw; /* Custom side padding */
  padding-right: 5vw; /* Custom side padding */
  padding-bottom: 0; /* Default bottom padding is 0, you can adjust if needed */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Center text if needed */
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}

/* Add scroll if content is scrollable */
.background-content.scrollable {
  overflow-y: auto;
}

@media (max-width: 768px) {
  .background-section {
    background-attachment: scroll; /* Disable fixed background on mobile */
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }

  .background-content {
    width: 90%; /* Make content width smaller on mobile */
    padding-left: 2vw;
    padding-right: 2vw;
  }
}






